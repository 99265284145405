<template>
  <v-container>
    <v-app-bar app clipped-right flat height="72">
      <v-toolbar-title>Available In-Game Screenshots</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <v-select
              v-model="genreFilter"
              :items="['Sports', 'Racing']"
              :menu-props="{ maxHeight: '400' }"
              label="Filter by Genres"
              multiple
              hint="Not working yet"
              persistent-hint
              solo
              clearable
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              v-model="ratioFilter"
              :items="filterRatio()"
              :menu-props="{ maxHeight: '400' }"
              label="Filter by Ratios"
              multiple
              hint="Not working yet"
              persistent-hint
              solo
              clearable
            ></v-select>
          </v-col>
          <v-col>
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              v-model="searchText"
              label="Search"
              placeholder="Search"
              solo
              clearable
              @click:clear="searchText = ''"
              hint="Search by keyphrase or text"
              persistent-hint
            ></v-text-field>
          </v-col>
        </v-row>
        <v-container fluid>
          <v-row dense>
            <v-col
              v-for="(template, index) in filteredList()"
              :key="`${index}-${template.id}`"
              :cols="3"
            >
              <router-link
                :to="{
                  path: `/scenes/${template.id}`,
                }"
              >
                <v-hover v-slot="{ hover }">
                  <v-card
                    class="mx-auto"
                    :elevation="hover ? 12 : 2"
                    :class="{ 'on-hover': hover }"
                  >
                    <v-img
                      :src="template.thumbnail"
                      height="200px"
                      cover
                      lazy-src="https://picsum.photos/seed/game/300/200?blur"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <v-card-title
                      ><div class="text-truncate">
                        {{ template.name }}
                      </div></v-card-title
                    >
                    <v-card-subtitle class="text-truncate">
                      {{
                        templateDescription(template.layers)
                      }}</v-card-subtitle
                    >
                    <!-- <v-card-text>
                      {{ index }}
                    </v-card-text> -->
                  </v-card>
                </v-hover>
              </router-link>
            </v-col>
          </v-row>
        </v-container>
        <v-progress-circular
          v-if="templateList.length === 0"
          color="primary"
          indeterminate
          :size="124"
          style="
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
          "
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ScenesV1',
  metaInfo: {
    title: 'Scenes',
  },

  data() {
    return {
      genreFilter: [],
      ratioFilter: [],
      templateList: [],
      // default to localstorage value, if expiry date is not 8hours in the future, refresh cache
      searchText:
        localStorage.getItem('searchText') !== null
          ? JSON.parse(localStorage.getItem('searchText')).expiry >
            Date.now() - 28800000
            ? JSON.parse(localStorage.getItem('searchText')).text
            : ''
          : '',
    }
  },
  mounted() {
    this.getTemplates()
  },
  watch: {
    searchText(val) {
      localStorage.setItem(
        'searchText',
        JSON.stringify({
          text: val === null ? '' : val,
          expiry: Date.now(),
        }),
      )
    },
  },
  methods: {
    async getTemplates() {
      // if localstorage exists, use it
      const storedObj =
        localStorage.getItem('getTemplates') !== null
          ? JSON.parse(localStorage.getItem('getTemplates'))
          : null
      let list = storedObj !== null ? storedObj.templateList : null
      let expiry = storedObj !== null ? storedObj.expiry : null
      // if list does not exist and expiry date is 2 minutes in the future, refresh
      if (list === null || expiry < Date.now() - 120000) {
        console.log('fetching new cache')
        let { data } = await axios({
          method: 'GET',
          url: `https://api.dynapictures.com/templates/?workspace_id=6d43898504`, // New: GET by Wrokspace ID
        })
        this.templateList = data
        // Set localStorage for template list
        localStorage.setItem(
          'getTemplates',
          JSON.stringify({
            templateList: this.templateList,
            expiry: Date.now(),
          }),
        )
      } else {
        console.log(expiry, 'using cached object')
        this.templateList = list
      }
    },
    filteredList() {
      // filter list and sort alphabetically
      return this.templateList
        .filter(template =>
          template.name.toLowerCase().includes(this.searchText.toLowerCase()),
        )
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    templateDescription(layers) {
      // remove layers containing the keyword background or canvas from description
      layers = layers.filter(
        layer =>
          !layer.name.toLowerCase().includes('background') &&
          !layer.name.toLowerCase().includes('canvas'),
      )
      let count = layers.length

      return `Requires (${count}) image${count > 1 ? 's' : ''}: ${layers
        .map(layer => {
          return `${layer.name}`
        })
        .join(', ')}.`
    },
    filterRatio() {
      // for each object in templateList, loop through the layers array and output every name unless it equals canvas or background
      // then filter the output to only include unique values
      // for each name remove words "creative_" and the number next to it, and underscores, and anything in parenthesis, keep the YYxZZ format
      // then sort numerically ascending
      // then return the array
      return this.templateList
        .map(template => {
          return template.layers
            .filter(
              layer =>
                !layer.name.toLowerCase().includes('background') &&
                !layer.name.toLowerCase().includes('canvas'),
            )
            .map(layer => {
              return layer.name
            })
            .filter((value, index, self) => self.indexOf(value) === index)
            .map(name => {
              return name
                .replace(/creative_\d+_/, '')
                .replace(/_/g, '')
                .replace(/\(.*?\)/g, '')
                .trim()
            })
            .sort((a, b) => (a > b ? 1 : -1))
        })
        .flat()
        .filter((value, index, self) => self.indexOf(value) === index)
    },
  },
}
</script>

<style lang="sass" scoped>
.col > a
  text-decoration: none
</style>
