<template>
  <div class="about">
    <h1>Template Two</h1>

    <v-container class="grey lighten-5 mb-6">
      <draggable
        v-model="staticList"
        class="row wrap fill-height align-center sortable-list"
        style="background: grey"
      >
        <v-flex
          v-for="row in staticList"
          :key="row.index"
          class="sortable"
          xs12
          my-2
        >
          <h2>Creative group {{ row.index }}</h2>
          <v-btn>Update properties</v-btn>
          <draggable :list="row.items" :group="{ name: 'row' }">
            <transition-group
              type="transition"
              class="row wrap justify-space-around draggable-area"
              style="min-height: 100px"
              :name="!drag ? 'flip-list' : null"
            >
              <span v-if="!row">Drag here!</span>
              <v-flex
                v-else
                v-for="item in row.items"
                :key="item.label"
                xs4
                pa-3
                class="row-v draggable-item"
              >
                <v-card style="height: 100px">{{ item.label }}</v-card>
              </v-flex>
            </transition-group>
          </draggable>
        </v-flex>

        <div slot="footer">
          <v-btn @click="addGroup">Add Group</v-btn>
        </div>
      </draggable>
    </v-container>
    <pre>
    {{ staticList }}
    </pre>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { BANNER_RATIOS } from '@/store/static-ratios.js'
export default {
  name: 'TemplateTwo',
  components: {
    draggable,
  },
  data: () => {
    return {
      staticList: [
        {
          index: 1,
          items: [...BANNER_RATIOS],
        },
      ],
      staticList2: BANNER_RATIOS,
      creativeSets: 1,
    }
  },
  methods: {
    addGroup() {
      this.staticList.push({
        index: this.staticList.length + 1,
        items: [],
      })
    },
  },
}
</script>

<style lang="scss" scoped>
// .sortable-list .flex:nth-child()

.draggable-area {
  padding: 20px;
  margin: 30px;
  border: 2px dashed blue;
}
.draggable-item {
  cursor: move;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
</style>
