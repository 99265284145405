import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { saveAs } from 'file-saver'
// const fs = require('fs')

// Dynapictures Stuff
const AUTH_TOKEN = 'c5991e35da14ca6f7adfeda341f6cfc12c4a17327e1b3273'
axios.defaults.headers.common['Authorization'] = 'Bearer ' + AUTH_TOKEN
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.common['Access-Control-Allow-Headers'] =
  'Origin, X-Requested-With, Content-Type, Accept'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    defaultParams: [
      {
        name: 'canvas',
        backgroundColor: '#fff',
      },
      {
        name: 'backgroundImage',
        imageUrl: 'https://dynapictures.com/images/banners/cat1.jpeg',
        borderColor: '#ddd',
        borderWidth: '2px',
        borderRadius: '5px',
        opacity: 1,
        // imagePosition: "align",
        // imageAlignH: "full",
        // imageAlignV: "top",
      },
      {
        name: 'logo',
        imageUrl: 'https://dynapictures.com/images/banners/cat1.jpeg',
        borderColor: '#ddd',
        borderWidth: '2px',
        borderRadius: '5px',
        opacity: 1,
        // imagePosition: "align",
        // imageAlignH: "full",
        // imageAlignV: "top",
      },
    ],
    templateOneList: [
      {
        id: '1535a1ae99',
        name: 'Template_1_9x40',
        thumbnail:
          'https://dynapictures.com/rest/public/designs/1535a1ae99/thumb.png',
        width: '1440',
        height: '6400',
      },
      {
        id: 'c1e94588a9',
        name: 'Template_1_9x16',
        thumbnail:
          'https://dynapictures.com/rest/public/designs/c1e94588a9/thumb.png',
        width: '1440',
        height: '2560',
      },
      {
        id: '51e41db0e9',
        name: 'Template_1_3x4',
        thumbnail:
          'https://dynapictures.com/rest/public/designs/51e41db0e9/thumb.png',
        width: '1440',
        height: '1920',
      },
      {
        id: 'c839a2e8f4',
        name: 'Template_1_3x2',
        thumbnail:
          'https://dynapictures.com/rest/public/designs/c839a2e8f4/thumb.png',
        width: '2160',
        height: '1440',
      },
      {
        id: 'ff174773ea',
        name: 'Template_1_2x3',
        thumbnail:
          'https://dynapictures.com/rest/public/designs/ff174773ea/thumb.png',
        width: '1440',
        height: '2160',
      },
      {
        id: '8fd257c252',
        name: 'Template_1_21x9',
        thumbnail:
          'https://dynapictures.com/rest/public/designs/8fd257c252/thumb.png',
        width: '3360',
        height: '1440',
      },

      {
        id: 'f26f5c886f',
        name: 'Template_1_4x3',
        thumbnail:
          'https://dynapictures.com/rest/public/designs/f26f5c886f/thumb.png',
        width: '1920',
        height: '1440',
      },
      {
        id: 'd38966dc63',
        name: 'Template_1_60x9',
        thumbnail:
          'https://dynapictures.com/rest/public/designs/d38966dc63/thumb.png',
        width: '9600',
        height: '1440',
      },
      {
        id: '0b77dbcc2f',
        name: 'Template_1_40x9',
        thumbnail:
          'https://dynapictures.com/rest/public/designs/0b77dbcc2f/thumb.png',
        width: '6400',
        height: '1440',
      },
      {
        id: 'b214aba1ae',
        name: 'Template_1_1x1',
        thumbnail:
          'https://dynapictures.com/rest/public/designs/b214aba1ae/thumb.png',
        width: '1440',
        height: '1440',
      },
      {
        id: '711f938cb0',
        name: 'Template_1_9x21',
        thumbnail:
          'https://dynapictures.com/rest/public/designs/711f938cb0/thumb.png',
        width: '1440',
        height: '3360',
      },
      {
        id: '9000b429dc',
        name: 'Template_1_16x9',
        thumbnail:
          'https://dynapictures.com/rest/public/designs/9000b429dc/thumb.png',
        width: '2560',
        height: '1440',
      },
    ],
    templateMockupsList: [
      {
        id: 'ddcbf30d50',
        name: 'BaseballClash_60x9',
        thumbnail:
          'https://dynapictures.com/rest/public/designs/ddcbf30d50/thumb.png',
        width: '3282',
        height: '2438',
      },
      {
        id: '6c9c5ab655',
        name: 'The Crew_21:9',
        thumbnail:
          'https://dynapictures.com/rest/public/designs/6c9c5ab655/thumb.png',
        width: '1280',
        height: '700',
      },
    ],
    ratios: [
      {
        label: '4:3',
        xlabel: '4x3',
        width: '1920',
        height: '1440',
      },
      {
        label: '16:9',
        xlabel: '16x9',
        width: '2560',
        height: '1440',
      },
      {
        label: '21:9',
        xlabel: '21x9',
        width: '3360',
        height: '1440',
      },
      {
        label: '40:9',
        xlabel: '40x9',
        width: '6400',
        height: '1440',
      },
      {
        label: '60:9',
        xlabel: '60x9',
        width: '9600',
        height: '1440',
      },
      {
        label: '1:1',
        xlabel: '1x1',
        width: '1440',
        height: '1440',
      },
      {
        label: '2:3',
        xlabel: '2x3',
        width: '1440',
        height: '2160',
      },
      {
        label: '3:2',
        xlabel: '3x2',
        width: '2160',
        height: '1440',
      },
      {
        label: '3:4',
        xlabel: '3x4',
        width: '1440',
        height: '1920',
      },
      {
        label: '9:16',
        xlabel: '9x16',
        width: '1440',
        height: '2560',
      },
      {
        label: '9:21',
        xlabel: '9x21',
        width: '1440',
        height: '3360',
      },
      {
        label: '9:40',
        xlabel: '9x40',
        width: '1440',
        height: '6400',
        layout: '2',
      },
    ],
  },
  getters: {
    defaultPreview(state) {
      return state.templateOneList
    },
    templateOneIds(state) {
      return state.templateOneList.map(ratio => {
        return ratio.id
      })
    },
    ratios(state) {
      return state.ratios
    },
  },
  // mutations: {
  //   setLoading({}) {},
  // },
  actions: {
    async generateAll({ getters, dispatch }, params) {
      const array = await Promise.all(
        getters.templateOneIds.map(templateID =>
          dispatch('generateImage', { templateID, params }),
        ),
      )
      return array
    },
    async generateImage(
      { state },
      { templateID, metadata, params = state.defaultParams },
    ) {
      // Default metadata label fits TemplateOne pattern
      // Needs to be modified later
      metadata =
        metadata ||
        `AutoGeneratorUI - ${
          state.templateOneList.filter(ctx =>
            templateID === ctx.id ? ctx.name : '',
          )[0].name
        }`
      let data = {
        params,
        format: 'png',
        metadata,
      }
      // Returns Image Object
      // Can be used in generate All or called indivually
      // Collect image url from this object
      let image = await axios({
        method: 'POST',
        url: `https://api.dynapictures.com/designs/${templateID}`,
        data,
      })
      console.log('generateImage', image)
      return await image.data
    },
    async downloadImage(ctx, { imageUrl, fileName }) {
      // Folder rname later maybe?
      // Custom prefixes maybe?
      console.log('Download: ', fileName, imageUrl)
      saveAs(imageUrl, `${fileName}`)
    },
    async uploadImage(ctx, { workspaceId, file }) {
      // Upload files before starting the generation process
      // The workspace where images get uploaded to in DynaPictures
      workspaceId = workspaceId || '0da457bca1' // Template_1_BasicLogo
      // Transform data into multipart for Dyna
      const formData = new FormData()
      formData.append('file', file)
      // Returns Image Object
      let image = await axios({
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        url: `https://api.dynapictures.com/media/${workspaceId}/assets`,
        data: formData,
        onUploadProgress: function (progressEvent) {
          console.log(progressEvent)
        },
      })
      console.log('uploadImage', image)
      return await image.data
    },
  },
  // modules: {},
})
