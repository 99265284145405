<template>
  <v-card :loading="loading" tile>
    <div :width="width" :height="height" class="imageWrapper">
      <img :src="imageUrl" />
    </div>
    <div v-if="!imageFullUrl">
      <v-card-text>
        <div>Preview of {{ metadata }}</div>
      </v-card-text>
    </div>
    <div v-if="imageFullUrl">
      <v-card-text>
        <div v-text="metadata"></div>
        <div class="text--primary">File Name: {{ fileName }}</div>
      </v-card-text>
      <v-card-actions>
        <v-dialog v-model="dialog" :height="height" :width="width">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="deep-purple lighten-2" text v-bind="attrs" v-on="on">
              <v-icon left>mdi-call-made</v-icon>
              View Full Size
            </v-btn>
          </template>

          <v-img
            contain
            :lazy-src="imageUrl"
            :src="imageFullUrl"
            max-height="100%"
          >
          </v-img>
          <!-- <img :src="imageFullUrl" alt=""> -->
        </v-dialog>
        <v-btn color="deep-purple lighten-2" text @click="downloadImage">
          Download
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
// Move download to parent component later
import store from '@/store'

export default {
  props: [
    'imageUrl',
    'loading',
    'imageFullUrl',
    'metadata',
    'fileName',
    'width',
    'height',
    'campaignId',
  ],
  // computed: {
  //   loading() {
  //     return this.data ? false : true;
  //   },
  //   imageUrl() {
  //     return this.data ? this.data.thumbnail : null;
  //   },
  // },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    downloadImage() {
      let campaignId = this.campaignId ? this.campaignId + '-' : ''
      let fileName = `${campaignId}${this.width}x${this.height}-${this.fileName}`
      let imageUrl = this.imageFullUrl
      store.dispatch('downloadImage', { imageUrl, fileName })
    },
  },
}
</script>

<style lang="scss" scoped>
.v-card {
  display: inline-block;
  margin: 10px;
}
.imageWrapper > img {
  display: block;
  max-width: 100%;
  max-height: 338px;
  margin: 0 auto;
}
</style>
