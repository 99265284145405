<template>
  <div class="widget">
    <v-container>
      <v-app-bar app clipped-right flat height="72">
        <v-btn icon :to="'/scenes'">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>{{ pageTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-app-bar>

      <v-row>
        <div
          class="dynapictures-form-widget"
          style="
            width: 100%;
            margin: auto;
            padding: 3rem 0;
            height: calc(100vh - 72px);
            width: calc(100vw);
            box-sizing: border-box;
          "
          :form-id="formId"
        >
          <v-progress-circular
            color="primary"
            indeterminate
            :size="124"
            style="
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            "
          ></v-progress-circular>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'DynaFormWidget',
  metaInfo() {
    const pageTitle = this.pageTitle || 'Dyna Form Widget'
    return {
      title: pageTitle,
    }
  },
  data() {
    return {
      breadcrumbs: [],
      name: '',
      formId: '',
      templateId: '',
    }
  },
  computed: {
    pageTitle() {
      return this.name
    },
    computeTemplateId() {
      // If data is present, otherwise use query param
      return this.templateId || this.$route.params.id
    },
  },
  mounted() {
    // If no props, get template based on URL
    this.getTemplate(this.computeTemplateId)
  },
  methods: {
    getTemplate(templateId) {
      // Get template based on URL
      axios
        .get(`https://api.dynapictures.com/templates/${templateId}`)
        .then(response => {
          console.log('response', response)
          this.name = response.data.name
          this.formId = response.data.formId
          this.templateId = response.data.id

          // Initialize page scripts
          this.initScript()
          this.initBreadcrumbs()
        })
        .catch(error => {
          console.log(error)
        })
    },
    initBreadcrumbs() {
      // Because we're using a dynamic route, we need to set the breadcrumbs manually
      this.breadcrumbs = [
        {
          text: 'Scenes',
          disabled: false,
          to: { name: 'ScenesV1' },
          exactPath: true,
        },
        {
          text: this.pageTitle,
          disabled: true,
        },
      ]
    },
    initScript() {
      // Set Widget Script, after we have the formId required
      let script = document.createElement('script')
      script.setAttribute(
        'src',
        'https://dynapictures.com/app/widgets/form-widget-v1.js',
      )
      script.setAttribute('id', 'dynapictures-form-loader')
      document.body.appendChild(script)
    },
  },
}
</script>
