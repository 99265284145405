export const BANNER_RATIOS = [
  {
    label: '4:3',
    xlabel: '4x3',
    width: '1920',
    height: '1440',
  },
  {
    label: '16:9',
    xlabel: '16x9',
    width: '2560',
    height: '1440',
  },
  {
    label: '21:9',
    xlabel: '21x9',
    width: '3360',
    height: '1440',
  },
  {
    label: '40:9',
    xlabel: '40x9',
    width: '6400',
    height: '1440',
  },
  {
    label: '60:9',
    xlabel: '60x9',
    width: '9600',
    height: '1440',
  },
  {
    label: '1:1',
    xlabel: '1x1',
    width: '1440',
    height: '1440',
  },
  {
    label: '2:3',
    xlabel: '2x3',
    width: '1440',
    height: '2160',
  },
  {
    label: '3:2',
    xlabel: '3x2',
    width: '2160',
    height: '1440',
  },
  {
    label: '3:4',
    xlabel: '3x4',
    width: '1440',
    height: '1920',
  },
  {
    label: '9:16',
    xlabel: '9x16',
    width: '1440',
    height: '2560',
  },
  {
    label: '9:21',
    xlabel: '9x21',
    width: '1440',
    height: '3360',
  },
  {
    label: '9:40',
    xlabel: '9x40',
    width: '1440',
    height: '6400',
  },
]
