<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      style="background: #101828"
      app
      width="300"
      :src="{
        src: blob,
        height: '100px',
      }"
    >
      <!-- Top Menu -->
      <v-list :dark="true">
        <v-list-item class="px-6 pr-8 pt-2">
          <v-list-item-content>
            <v-img :src="logo"></v-img>
            <v-list-item-subtitle
              v-text="'Alpha Version 0.1.1'"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-sheet color="transparent" height="128" width="100%"></v-sheet>

      <!-- Second Menu -->
      <v-list :dark="true" dense nav>
        <v-list-item
          v-for="item in items"
          :key="item.text"
          link
          :to="item.link"
          color="#ffffff"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-divider></v-divider>
        <v-list :dark="true" dense nav>
          <v-list-item
            v-for="item in logoutItems"
            :key="item.text"
            link
            @click="logout"
            color="#ffffff"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
// Vercel Speed Insights
import { webVitals } from './vitals.js'
import logo from '@/assets/Logo-wide.svg'
import blob from '@/assets/blob.png'

export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Frameplay Mockup Generator',
    // all titles will be injected into this template
    titleTemplate: `%s | Frameplay Mockup Generator`,
  },
  data: () => ({
    logo,
    blob,
    drawer: true,
    items: [
      {
        icon: 'mdi-image-area',
        text: 'In-Game Screenshots',
        link: { name: 'ScenesV1' },
      },
    ],
    logoutItems: [
      {
        icon: 'mdi-logout',
        text: 'Log Out',
      },
    ],
  }),
  mounted() {
    let analyticsId = process.env.VUE_APP_VERCEL_ANALYTICS_ID

    if (analyticsId) {
      webVitals({
        path: this.$route.path,
        params: this.$route.params,
        analyticsId,
        debug: true,
      })
    }
  },
  computed: {
    profilePic() {
      return this.$auth.user.picture
    },
  },
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      })
    },
  },
}
</script>
