import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from '../auth/authGuard'

// import HomeView from "../views/Home.vue";
import ProfileView from '../views/Profile.vue'
import TemplateOne from '../views/TemplateOne.vue'
import TemplateTwo from '../views/TemplateTwo.vue'
import ScenesV1 from '../views/ScenesV1.vue'
import DynaFormWidget from '../views/DynaFormWidget.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'ScenesV1' },
  },
  {
    path: '/profile',
    name: 'profileView',
    component: ProfileView,
  },
  {
    path: '/one',
    name: 'templateOne',
    component: TemplateOne,
  },
  {
    path: '/two',
    name: 'templateTwo',
    component: TemplateTwo,
  },
  {
    path: '/scenes',
    name: 'ScenesV1',
    component: ScenesV1,
  },
  {
    path: '/scenes/:id',
    name: 'ScenesChildren',
    component: DynaFormWidget,
  },
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
})

router.beforeEach(authGuard)

export default router
