import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta'
// Vercel Analytics
import { inject } from '@vercel/analytics'
inject()

// Import the Auth0 configuration
import authConfig from '../auth_config.json'
const domain = authConfig.domain
const clientId = authConfig.clientId
// Import the plugin here
import { Auth0Plugin } from './auth'

Vue.use(VueMeta)

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    )
  },
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
