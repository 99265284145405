<template>
  <v-container>
    <v-row>
      <v-col>
        <img :src="$auth.user.picture" />
        <h2>{{ $auth.user.nickname }}</h2>
        <p>{{ $auth.user.email }}</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <pre>{{ JSON.stringify($auth.user, null, 2) }}</pre>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'profileView',
}
</script>
